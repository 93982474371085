<template>
  <v-form lazy-validation v-model="valid" ref="form">
    <v-row>
      <v-col cols="12" sm="12" md="11" lg="11">
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Forwarder <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-autocomplete
                  outlined
                  flat
                  dense
                  background-color="white"
                  append-icon="mdi-magnify"
                  :items="forwarders"
                  item-text="name"
                  return-object
                  v-model="vendor"
                  @change="onChangeVendor"
                  :rules="[v => !!v || 'Forwarder is required']"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Pay For <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-autocomplete
                  outlined
                  flat
                  dense
                  background-color="white"
                  append-icon="mdi-magnify"
                  :items="listPayFor"
                  v-model="form.payFor"
                  @change="onChangePayFor"
                  :rules="[v => !!v || 'Pay For is required']"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Invoice Number <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.invoiceNumber"
                  :rules="[v => !!v || 'Invoice Number is required']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <!-- <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Reimbursement Number</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-autocomplete
                  flat
                  dense
                  outlined
                  background-color="white"
                  v-model="replacementCost"
                  return-object
                  item-text="sequence"
                  :items="dropdowns"
                  @change="onChangeReplacementCost"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-col> -->
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Invoice Date <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-menu
                  v-model="menuInvoiceDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      flat
                      dense
                      background-color="white"
                      v-model="form.invoiceDate"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.invoiceDate"
                    :allowed-dates="getAllowedDates()"
                    @input="menuInvoiceDate = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Received Date <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-menu
                  v-model="menuInvoiceReceivedDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      flat
                      dense
                      background-color="white"
                      v-model="form.invoiceReceivedDate"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.invoiceReceivedDate"
                    @input="menuInvoiceReceivedDate = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader> Term <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  type="number"
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.invoiceDuration"
                  suffix="days"
                  @input="onInputInvoiceDuration"
                  :rules="[v => v >= 0 || 'Term must more than equals 0']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader> Due Date <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.invoiceDueDate"
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Tax Invoice Serial Number</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.taxInvoiceSerialNumber"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6" v-if="form.payFor === 'Pengiriman'">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>SPK</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-autocomplete
                  outlined
                  flat
                  dense
                  background-color="white"
                  append-icon="mdi-magnify"
                  :items="listSpk"
                  v-model="spk"
                  return-object
                  @change="onChangeSpk"
                >
                  <template v-slot:selection="data">
                    {{ data.item.spk_num + data.item.spk_num_seq }}
                  </template>
                  <template v-slot:item="data">
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="data.item.spk_num + data.item.spk_num_seq"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Tax Date</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-menu
                  v-model="menuTaxDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      flat
                      dense
                      background-color="white"
                      v-model="form.taxDate"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.taxDate"
                    @input="menuTaxDate = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>DPP</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.totalDpp"
                  type="number"
                  persistent-hint
                  :hint="formatPrice(form.totalDpp)"
                  :rules="[v => v >= 0 || 'DPP must more than equal 0']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-tabs vertical>
              <v-tab background-color="light-blue accent-3" dark class="px-0">
                <v-icon>
                  mdi-note-text
                </v-icon>
              </v-tab>
              <v-tab-item>
                <v-card flat class="px-2">
                  <v-row>
                    <v-col cols="6">
                      <v-autocomplete
                        outlined
                        flat
                        dense
                        background-color="white"
                        append-icon="mdi-magnify"
                        :items="accounts"
                        item-text="accountName"
                        return-object
                        v-model="account"
                        :filter="filterObject"
                      >
                        <template v-slot:item="data">
                          <v-list-item v-on="data.on" @click.stop="addAccount(data.item)">
                            <v-list-item-content>
                              <v-list-item-title v-html="data.item.accountName"></v-list-item-title>
                              <v-list-item-subtitle
                                v-html="data.item.accountNumber"
                              ></v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="6" align-self="start" class="text-right pb-0">
                      <p class="headline font-italic">Account <span class="red--text">*</span></p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-data-table
                        :headers="headers"
                        :items="items"
                        disable-pagination
                        hide-default-footer
                        @click:row="onClickRow"
                      >
                        <template v-slot:item.no="{ item }">
                          {{ items.map(x => x).indexOf(item) + 1 }}
                        </template>
                        <template v-slot:item.total="{ item }">
                          {{ formatPrice(item.total) }}
                        </template>
                        <template v-slot:item.rate="{ item }">
                          {{ formatPrice(item.rate) }}
                        </template>
                        <template v-slot:item.totalIDR="{ item }">
                          {{ formatPrice(item.totalIDR) }}
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="12" md="1" lg="1" class="py-0">
        <v-row class="py-0">
          <v-col cols="12" class="mb-4 py-0">
            <v-btn color="primary" @click="checkJournal">
              <v-icon large>
                mdi-content-save
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <dialog-account
      :item="item"
      :index="index"
      :dialog="dialog"
      @close="close"
      @save="addItem"
      @deleteItem="deleteItem"
    ></dialog-account>
    <dialog-review-journal
      :dialog="dialogReview"
      :journals="journals"
      @save="submit"
      @close="close"
      currency="IDR"
    ></dialog-review-journal>
  </v-form>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import DialogAccount from "@/components/DialogAccount";
import DialogReviewJournal from "@/components/DialogReviewJournal";
import { fiscalMonthMixin } from "@/services/mixins/fiscalMonthMixin";

export default {
  name: "create-purchase-other-invoice",
  mixins: [fiscalMonthMixin],
  components: {
    "dialog-account": DialogAccount,
    DialogReviewJournal,
  },
  data: () => ({
    defaultForm: {
      formNumber: "",
      invoiceNumber: "",
      invoiceDate: moment().format("yyyy-MM-DD"),
      taxDate: moment().format("yyyy-MM-DD"),
      invoiceReceivedDate: moment().format("yyyy-MM-DD"),
      invoiceDueDate: moment().format("yyyy-MM-DD"),
      invoiceDuration: 0,
      vendorId: null,
      vendorName: "",
      reimbursementNumber: "",
      spkId: null,
      address: "",
      payFor: "",
      taxInvoiceSerialNumber: "",
      replacementCostId: null,
      accounts: [],
      status: "Outstanding",
      totalDpp: 0,
    },
    valid: true,
    menuInvoiceDate: false,
    menuInvoiceReceivedDate: false,
    menuTaxDate: false,
    form: {},
    items: [],
    vendor: null,
    dialog: false,
    dialogReview: false,
    item: {},
    index: -1,
    account: null,
    replacementCost: null,
    accounts: [],
    listSpk: [],
    listPayFor: ["Pengiriman", "Tiket", "Sewa", "Handling", "Instalasi", "Pajak", "Lainnya"],
    spk: {},
    journals: [],
    headers: [
      {
        text: "No",
        value: "no",
        sortable: false,
        divider: true,
      },
      {
        text: "Account #",
        value: "accountNumber",
        sortable: false,
        divider: true,
      },
      {
        text: "Account Name",
        value: "accountName",
        sortable: false,
        divider: true,
      },
      {
        text: "D/C",
        value: "nature",
        sortable: false,
        divider: true,
      },
      {
        text: "Remark",
        value: "remark",
        sortable: false,
        divider: true,
      },
      {
        text: "Currency",
        value: "currency",
        sortable: false,
        divider: true,
      },
      {
        text: "Amount",
        value: "total",
        sortable: false,
        divider: true,
      },
      {
        text: "Rate",
        value: "rate",
        sortable: false,
        divider: true,
      },
      {
        text: "Amount in RP",
        value: "totalIDR",
        sortable: false,
        divider: true,
      },
      {
        text: "Project",
        value: "project",
        sortable: false,
        divider: true,
      },
      {
        text: "Dept",
        value: "department",
        sortable: false,
        divider: true,
      },
      {
        text: "Reff",
        value: "reference",
        sortable: false,
        divider: true,
      },
    ],
  }),

  computed: {
    ...mapState("purchaseOtherInvoice", ["lastFormNumber"]),
    ...mapState("external", ["forwarders"]),
    ...mapState("replacementCost", ["dropdowns"]),
  },

  watch: {},

  methods: {
    checkJournal() {
      if (this.$refs.form.validate() && this.items.length > 0) {
        this.journals = [];
        this.items.forEach(x => {
          if (x.nature === "Debit") {
            this.journals.unshift({
              accountNumber: x.accountNumber,
              accountName: x.accountName,
              debit: x.total,
              credit: 0,
            });
          } else {
            this.journals.push({
              accountNumber: x.accountNumber,
              accountName: x.accountName,
              debit: 0,
              credit: x.total,
            });
          }
        });
        this.journals.forEach(x => {
          this.$store
            .dispatch("chartOfAccount/getPointer", { accountNumber: x.accountNumber })
            .then(response => (x.pointers = response.data));
        });
        this.dialogReview = true;
      } else {
        this.$store.dispatch("toast", {
          type: "error",
          message: "Items is required",
        });
      }
    },
    async submit() {
      if (this.$refs.form.validate() && this.items.length > 0) {
        this.form.formNumber = this.lastFormNumber;
        this.form.accounts = this.items;

        let totalDebit = 0;
        let totalCredit = 0;

        this.items.forEach(x => {
          if (x.nature === "Debit") {
            totalDebit += x.total;
          } else {
            totalCredit += x.total;
          }
        });

        if (totalDebit - totalCredit === 0) {
          this.$store.commit("SET_LOADER", true);
          await this.$store
            .dispatch("purchaseOtherInvoice/create", this.form)
            .then(() => this.$store.commit("SET_LOADER", false))
            .catch(() => {
              this.$store.dispatch("toast", {
                type: "error",
                message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
              });
              this.$store.commit("SET_LOADER", false);
            });
        } else {
          let different = totalDebit - totalCredit;
          if (different > 0) {
            this.$store.dispatch("toast", {
              type: "error",
              message: `Journal not balance with -${Math.abs(different)} on Debit`,
            });
          } else {
            this.$store.dispatch("toast", {
              type: "error",
              message: `Journal not balance with -${Math.abs(different)} on Dredit`,
            });
          }
        }
      } else {
        this.$store.dispatch("toast", {
          type: "error",
          message: "Accounts is required",
        });
      }
    },
    onChangeVendor(val) {
      this.form.vendorId = val.id;
      this.form.vendorName = val.name;
      // this.getVendorAddress(val.id);
    },
    async getVendorAddress(id) {
      const response = await this.$store.dispatch("external/getVendorAddress", id);
      if (response.status === 200) {
        this.form.address =
          response.data.address_line_1 +
          ", " +
          response.data.post_code +
          ", " +
          response.data.city +
          ", " +
          response.data.country_id;
      }
    },
    onChangePayFor(val) {
      this.$store
        .dispatch("purchaseOtherInvoice/getAccounts", val)
        .then(response => (this.items = response.data));
    },
    invoiceDueDate() {
      let dueDate = moment(this.form.invoiceReceivedDate)
        .add(this.form.invoiceDuration, "days")
        .format("yyyy-MM-DD");
      return dueDate;
    },
    onInputInvoiceDuration() {
      this.form.invoiceDueDate = this.invoiceDueDate();
    },
    deleteItem(index) {
      if (index > -1) {
        this.items.splice(index, 1);
        this.dialog = false;
      }
    },
    close() {
      this.dialog = this.dialogReview = false;
    },
    addItem(item) {
      if (this.index > -1) {
        this.items[this.index] = item;
      } else {
        this.items.push({ ...item });
      }
      this.account = null;
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    clear() {
      this.vendor = null;
      this.account = null;
      this.items = [];
      this.form = Object.assign({}, this.defaultForm);
    },
    onClickRow(item) {
      this.item = item;
      this.index = this.items.map(x => x).indexOf(item);
      this.dialog = true;
    },
    addAccount(item) {
      this.item = item;
      this.index = this.items.findIndex(x => x === item);
      this.dialog = true;
    },
    async getAccounts() {
      const response = await this.$store.dispatch("chartOfAccount/getOtherAccounts");
      if (response.status === 200) {
        this.accounts = response.data;
      }
    },
    onChangeReplacementCost(val) {
      this.form.replacementCostId = val.id;
    },
    onChangeSpk(val) {
      this.form.spkId = val.id;
      this.items.forEach(x => {
        if (x.accountNumber === "720.00000") {
          x.total = val.price;
          x.totalIDR = val.price * x.rate;
        }
      });
    },
    // eslint-disable-next-line no-unused-vars
    filterObject(item, queryText, itemText) {
      return (
        item.accountName.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
        item.accountNumber.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
      );
    },
    async getSpk() {
      await this.$store
        .dispatch("external/getSpk")
        .then(response => (this.listSpk = response.data));
    },
    async getInitialData() {
      this.$store.commit("SET_LOADER", true);
      Promise.all([
        this.getAccounts(),
        this.getSpk(),
        await this.$store.dispatch("purchaseOtherInvoice/getLastFormNumber"),
        await this.$store.dispatch("external/getForwarders"),
        await this.$store.dispatch("replacementCost/getDropDown"),
      ])
        .then(() => {
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
  },

  mounted() {
    this.getInitialData();
    this.form = Object.assign({}, this.defaultForm);
  },
};
</script>

<style></style>
